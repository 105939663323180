import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { useSearchParams, useParams, useLocation } from 'react-router-dom';
import useRead from '../../hooks/useRead';
import usePut from '../../hooks/usePut';
import Moment from 'react-moment';
import Pagination from '../../components/pagination';
import toast, { Toaster } from 'react-hot-toast';
import PrimaryLoader from '../../components/common/PrimaryLoader';
import { EyeIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import DatePicker from "react-datepicker";

const SinglePersonWebcheckin = () => {
    const PunchTypes = {
        'punch_in': 'Punch In',
        'punch_out': 'Punch Out'
    }
    const punchApproveTypes = [
        {
            name: 'Approve',
            value: '1'
        },
        {
            name: 'Pending',
            value: '2'
        },
        {
            name: 'Reject',
            value: '0'
        }
    ]
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams()
    const location = useLocation();  // Use useLocation to get the current location
    
    // Extract the employee_name from the query parameters
    const queryParams = new URLSearchParams(location.search);
    const employeeName = queryParams.get("employee_name");
    console.log(employeeName,'employee_name');
    let initialFilter = {
        month_and_year: searchParams.get('month_and_year') || new Date(),
        user_id: id,
        page_number: 0,
        limit: 10
    }

    const [checkInData, setCheckInData] = useState([])
    const { list, paramsObject, setGetListParams } = useRead({
        url: 'checked_in_user_list',
        initialData: initialFilter,
        onSuccess: (res) => {
            setCheckInData(res.data)
        },
        onError: () => {
            toast.error('There was an error while retrieving the data; please try again later.')
        }
    })
    const statusChange = usePut({
        url: 'approve_punch_details',
        onSuccess: () => {
            toast.success('Status Updated Successfully')
        },
        onError: () => {
            toast.error('There was an error while retrieving the data; please try again later.')
        }
    })
    const handleChangeStatus = (e, id, index) => {
        let params = {
            approval_status: e.target.value,
            id
        }
        let data = checkInData
        data[index]['approval_status'] = e.target.value
        setCheckInData(checkInData)
        statusChange.handleAuth.mutate(params)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleDate = (value, name) => {
        let data = {
            ...paramsObject,
            [name]: value,
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    return (
        <>
        <h2 className="text-2xl font-semibold ml-4 text-primary mb-4">
                   {employeeName || 'Employee'}
                </h2>
            <div className=' w-[25%] px-5'>
                <label className=' roboto p-2 label'>Select Month/Year</label>
                <DatePicker required name='month_and_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={paramsObject?.month_and_year} onChange={(value) => handleDate(value, 'month_and_year')} />
            </div>
            {list.isLoading &&
                <div className=' fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-[#ffffffc7]'>
                    <PrimaryLoader />
                </div>}
            <Toaster />
            <div className="mb-12">
                {list.data?.data?.length <= 0
                    ? <p className=' text-center font-semibold text-gray-700 mt-10'>No Data Found</p>
                    : <div>
                        <div className="max-w-full overflow-x-auto px-4 mt-8">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className=" bg-[color:var(--color1)] text-center">
                                        <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                        <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Name</th>
                                        <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                                        <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Time</th>
                                        <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Photo</th>
                                        <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Location</th>
                                        <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4"> Approval Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {checkInData?.map((data, idx) => {
                                        const { name, email, id, type, punch_time, approval_status, media_url, location_lat, location_long } = data
                                        return <tr key={idx} className='bg-gray-200 hover:bg-gray-100 text-sm'>
                                            <td className="text-center text-dark font-medium py-3 px-2 border-b border-l border-[#E8E8E8]">{idx + 1}</td>
                                            <td className="text-center text-dark font-medium py-3 px-2 border-b border-l border-[#E8E8E8]">{name}</td>
                                            <td className="text-center text-dark font-medium py-3 px-2 border-b border-[#E8E8E8]">{email}</td>
                                            <td className="text-center text-dark font-medium py-3 px-2 border-b border-[#E8E8E8]">
                                                <Moment date={punch_time} unix format='llll' />
                                            </td>
                                            <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                                                {media_url
                                                    ? <a target="_blank" rel="noopener noreferrer" href={media_url} className=' flex items-center justify-center'>
                                                        <EyeIcon className=' w-6 h-6 cursor-pointer text-black' />
                                                    </a>
                                                    : '---'}
                                            </th>
                                            <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 text-center">
                                                <div className='flex items-center justify-center'>
                                                    <a href={`https://www.google.com/maps?q=${location_lat},${location_long}`} target="_blank" rel="noopener noreferrer" className='flex flex-col items-center justify-center'>
                                                        <LocationMarkerIcon className=' w-6 h-6 cursor-pointer text-black' />
                                                    </a>
                                                </div>
                                            </th>
                                            <td className="text-center text-dark font-medium py-3 px-2 border-b border-[#E8E8E8]">
                                                <div>
                                                    <select onChange={(e) => handleChangeStatus(e, id, idx)} value={approval_status} className='input'>
                                                        {punchApproveTypes.map((item, idx) => {
                                                            return <option key={idx} value={item.value}>{item.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='px-5 mt-5'>
                            <Pagination
                                currentPage={+paramsObject.page_number}
                                lengthofItems={list?.data?.total_count}
                                limit={+paramsObject.limit}
                                onPageChange={handlePageClick}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default SinglePersonWebcheckin