import { EyeIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import useGet from "../../hooks/read/useGet";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "../../components/pagination";
import PrimaryLoader from "../../components/common/PrimaryLoader";
import DownloadAttendanceModal from "../../components/modal/DownloadAttendanceModal";
import { Switch } from "@mui/material";
import DateWiseAttendance from "./DateWiseAttendance";
import { getsingleDayWiseUsersAttendanceData } from "../../api/attendance";

const Attendance = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [datewiseAttendanceData, setDatewiseAttendanceData] = useState(null); // State to store data from DateWiseAttendance
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isUserwise, setIsUserwise] = useState(true); // State to track the switch position

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  const initialEmployeeState = {
    page_number: searchParams.get("page_number") || 0,
    limit: searchParams.get("limit") || 10,
    search_query: searchParams.get("search_query") || "",
  };
  const {
    list: employeeList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "employeelist",
    initialData: initialEmployeeState,
    onSuccess: () => {
      scrollToTop();
      // toast.success('Employees Fetched Successfully')
    },
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_number: val.selected,
    };
    setGetListParams(data);
    setSearchParams(data);
  };
  const handleDatewiseAttendanceData = (data) => {
    // Handle the data passed from the child component
    setDatewiseAttendanceData(data);
  };

  const handleSearch = async (e) => {
    const { name, value } = e.target;
    const updatedParams = {
      ...paramsObject,
      month_year: selectedDate,
      page_number: 0,
      [name]: value,
    };

    setGetListParams(updatedParams);
    setSearchParams(updatedParams);

    if (!isUserwise) {
      // If in Datewise mode, call the date-wise attendance API
      try {
        const response = await getsingleDayWiseUsersAttendanceData(
          updatedParams
        );
        // Handle the response here, for example, updating the state with the fetched data
        // console.log(response.data);
        // Assuming you want to update paramsObject with the new data
        setGetListParams({ ...updatedParams, data: response.data });
      } catch (error) {
        console.error("Error fetching date-wise attendance data:", error);
        toast.error("Failed to fetch date-wise attendance data.");
      }
    }
  };
  const handleDateChange = (date) => {
    // Handle the date change here
    // console.log("Selected Date:", date);
    setSelectedDate(date);
  };

  const handleToggleUser = () => {
    setIsUserwise(!isUserwise);
    let data = {
      ...paramsObject,
      page_number: 0,
      search_query: "",
    };
    setGetListParams(data);
    setSearchParams(data);
  };

  return (
    <>
      <DownloadAttendanceModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
      />
      <Toaster />
      <>
        {isUserwise ? (
          <div className="flex items-center justify-between px-4 mb-10">
            <div className=" w-[400px]">
              <input
                type="search"
                placeholder="Search By Name..."
                name="search_query"
                className="input"
                value={paramsObject.search_query}
                onChange={handleSearch}
              />
            </div>
            <div className="flex  items-center gap-2">
              <p className="">Datewise</p>
              <Switch checked={isUserwise} onChange={handleToggleUser} />
              <p> Userwise</p>
            </div>
            <button
              type="button"
              onClick={() => setShowDownloadModal(true)}
              className=" bg-[color:var(--color1)] text-white rounded px-4 py-2"
            >
              Download Report
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-between px-4 mb-10">
            <div className=" w-[400px]">
              <input
                type="search"
                placeholder="Search By Name..."
                name="search_query"
                className="input"
                value={paramsObject.search_query}
                onChange={handleSearch}
              />
            </div>
            <div className="flex  items-center gap-2">
              <p className="">Datewise</p>
              <Switch checked={isUserwise} onChange={handleToggleUser} />
              <p> Userwise</p>
            </div>
            <button
              type="button"
              onClick={() => setShowDownloadModal(true)}
              className=" bg-[color:var(--color1)] text-white rounded px-4 py-2"
            >
              Download Report
            </button>
          </div>
        )}
      </>
      {isUserwise ? (
        <>
          {employeeList.isLoading ? (
            <div className=" flex items-center justify-center mt-10">
              {" "}
              <PrimaryLoader />
            </div>
          ) : (
            <>
              {employeeList?.data?.data?.length <= 0 ? (
                <div className="text-center">
                  <p className=" text-gray-700 text-3xl">No Users To Show</p>
                </div>
              ) : (
                <div class="max-w-full overflow-x-auto px-4">
                  <table class="table-auto w-full">
                    <thead>
                      <tr class=" bg-[color:var(--color1)] text-center">
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                          Name
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          Email
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          User Type
                        </th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          Attendance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeList?.data?.data?.map((data, index) => {
                        const { employee_name, email, id, type_of_employee } =
                          data;
                        return (
                          <tr key={index}>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                              {employee_name}
                            </td>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                              {email}
                            </td>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">
                              {type_of_employee}
                            </td>
                            <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                              <div className="flex items-center justify-center w-full">
                                <Link
                                  to={`/view-attendance/${id}?employee_name=${employee_name}`}
                                  className="bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center"
                                >
                                  <EyeIcon className="w-6 h-6" />
                                  <span>View</span>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="px-5 mt-5 mb-10">
                <Pagination
                  currentPage={+paramsObject.page_number}
                  lengthofItems={employeeList.data?.total_count}
                  limit={+paramsObject.limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <DateWiseAttendance onDateChange={handleDateChange} />
      )}
    </>
  );
};

export default Attendance;
