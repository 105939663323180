import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import LikertView from '../../components/likert';
import usePut from '../../hooks/usePut'
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '../../components/ui';

const AdvanceShiftEditing = ({ open, onClose, shiftData, mode }) => {
  // console.log(shiftData);
  const weekendObject = {
    sunday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    monday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    tuesday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    wednesday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    thursday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    friday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    saturday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ]
  }
  let formData = {
    shiftName: '',
    shiftInTime: '',
    shiftOutTime: '',
    shiftGraceInTime: '',
    shiftGraceOutTime: '',
    workingTypeMode: 'strict',  // strict | lenient
    minimum_working_hours: '',
    weekendDefinition: weekendObject,
  }

  const [shiftFormData, setShiftFormData] = useState(formData)
  useEffect(() => {
    if (shiftData) {
      const { weekendDefinition } = shiftData || {}
      let newWeekendDefinition = JSON.parse(JSON.stringify(weekendObject))
      Object.keys(newWeekendDefinition).forEach((weekEndKey) => {
        if (weekEndKey in weekendDefinition) {
          console.log(weekendDefinition);
          weekendDefinition[weekEndKey].forEach((weekEndData, idx) => {
            if (newWeekendDefinition[weekEndKey][idx].type === +weekEndData.type || +weekEndData.type === NaN) {
              newWeekendDefinition[weekEndKey][idx] = weekEndData
            }
          })
        }
      })
      let data = {
        ...shiftData,
        weekendDefinition: newWeekendDefinition
      }
      setShiftFormData(data)
    }
  }, [shiftData])
  const handleSetShiftFormData = (e) => {
    let name = e.target.name
    let value = e.target.value
    let prevShiftData = shiftFormData
    prevShiftData[name] = value
    setShiftFormData({ ...shiftFormData })
  }
  const handleUpdateWeekendDefinition = (object) => {
    let prevShiftData = shiftFormData
    prevShiftData.weekendDefinition = object
    setShiftFormData({ ...shiftFormData })
  }
  const { handleAuth } = usePut({
    url: 'shift_create',
    refreshUrl: 'shift_listing',
    onSuccess: () => {
      toast.success('Shift Created Successfully')
      onClose()
      clearData()
    },
    onError: (err) => {
      const { status, data } = err
      if (status >= 400 || status <= 499) {
        toast.error(data.message)
      } else {
        toast.error('Internal Server Error')
      }
    }
  })
  const handleSubmitShiftData = (e) => {
    e.preventDefault()
    handleAuth.mutate(shiftFormData)
  }
  const handleCloseDialog = () => {
    clearData()
    onClose()
  }
  const clearData = () => {
    setShiftFormData(formData)
  }

  return (
    <>
      <Toaster />
      <Drawer anchor={'right'} open={open} onClose={handleCloseDialog}>
        <div className='h-full bg-white px-8' style={{ width: 'calc(100vw - 250px)' }}>
          <form className=' space-y-8' onSubmit={handleSubmitShiftData}>
            <div className='flex items-center justify-between  py-5 border-b border-gray-200 sticky z-40 top-0 bg-white'>
              <div className='flex items-center space-x-3'>
                <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={handleCloseDialog} />
                <p className='text-gray-700 text-3xl font-semibold'>Shift Details</p>
              </div>
              <Button className={'px-4 py-2'} type='submit'>
                Save Shift
              </Button>
            </div>
            <div className=' '>
              <div className='grid grid-cols-3 gap-5 py-5'>
                <div>
                  <div className=''>
                    <label className='label'>Shift Name: </label>
                    <input type="text" name='shiftName' disabled={mode === 'edit'} value={shiftFormData.shiftName} onChange={handleSetShiftFormData} required={true} className='input' placeholder='Enter Shift name ' />
                  </div>
                </div>
                <>
                  <>
                    <div>
                      <div>
                        <label className='label'>Shift In-Time: </label>
                        <input type="time" name='shiftInTime' value={shiftFormData.shiftInTime} onChange={handleSetShiftFormData} required={true} className='input' />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='label'>Shift Out-Time: </label>
                        <input type="time" name='shiftOutTime' required={true} value={shiftFormData.shiftOutTime} onChange={handleSetShiftFormData} className='input' />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='label'>Shift Grace In-Time: </label>
                        <input type="time" name='shiftGraceInTime' value={shiftFormData.shiftGraceInTime} onChange={handleSetShiftFormData} required={true} className='input' />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='label'>Shift Grace Out-Time: </label>
                        <input type="time" name='shiftGraceOutTime' required={true} value={shiftFormData.shiftGraceOutTime} onChange={handleSetShiftFormData} className='input' />
                      </div>
                    </div>
                    <div>
                      <label className='label'>Minimum Working Hours: </label>
                      <input type="number" name='minimum_working_hours' required={true} value={shiftFormData.minimum_working_hours} onChange={handleSetShiftFormData} className='input' />
                      <small className=' text-red-500 font-semibold'>Enter in hours</small>
                    </div>
                  </>
                </>
              </div>
            </div>
            {shiftFormData.workingTypeMode !== 'lenient' &&
              <div className='py-5'>
                <p className='text-gray-700 text-3xl font-semibold mb-4'>Weekend Definition</p>
                <LikertView
                  mode={mode}
                  onChange={(values) => handleUpdateWeekendDefinition(values)}
                  values={shiftFormData.weekendDefinition}
                />
              </div>}
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default AdvanceShiftEditing