import React from 'react'
import useGetUserLoanLogs from '../../../hooks/Loan/useGetUserLoanLogs'
import Pagination from '../../../components/pagination'
import { useParams } from 'react-router-dom'

const EmployeeRepaymentLogs = () => {
  const { responseData, paramsObject, handlePageClick } = useGetUserLoanLogs()
  const { username } = useParams()

  return (
    <>
      <div className="px-4 pb-4 font-bold shadow-md">
          Loan Records for "{username}"
      </div>
      <div className='px-4 py-4'>
        <div class="max-w-full overflow-x-auto px-4 mt-5">
          <table class="table-auto w-full">
            <thead>
              <tr class=" bg-[color:var(--color1)] text-center">
                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                {/* Base Amount */}
                Sr. No
                </th>
                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Emi Amount</th>
                <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Repayment Date</th>
                {/* <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Status</th> */}
                {/* <th class="w-1/6 min-w-[160px] text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">EMI Rate</th> */}
              </tr>
            </thead>
            <tbody>
              {responseData?.data?.data?.length <= 0
                ? <>
                  <tr>
                    <td colSpan={5}>
                      <div className='text-center py-2 text-gray-600'>No Records Found</div>
                    </td>
                  </tr>
                </>
                : <>
                  {responseData?.data?.data.map((item, index) => {
                    const { amount, emi_amount, emi_duration, from_date, id, installment_date, month_year, actual_amount, processing_status } = item || {}
                    return <tr key={index}>
                      <td class="text-center text-dark font-medium text-base py-2 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                        {/* {actual_amount} */}
                        {index + 1}
                      </td>
                      <td class="text-center text-dark font-medium text-base py-2 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{amount}</td>
                      <td class="text-center text-dark font-medium text-base py-2 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{installment_date}</td>
                      {/* <td class="text-center text-dark font-medium text-base py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{processing_status}</td> */}
                      {/* <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">{emi_amount}</td> */}
                    </tr>
                  })}
                </>}
            </tbody>
          </table>
          <div className='px-5 mt-5 mb-10'>
            <Pagination
              currentPage={+paramsObject.page_number}
              lengthofItems={responseData.data?.total_count}
              limit={+paramsObject.limit}
              onPageChange={handlePageClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeRepaymentLogs


{/* <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                      <div className='flex items-center justify-center w-full space-x-3'>
                        <Link to={`/loan-repayment-log/${id}/${username}/${id}`}>
                          <EyeIcon className='h-6 text-gray-600 w-6 hover:text-blue-600' />
                        </Link>
                        <div>
                          <TrashIcon className=' text-gray-600 w-6 h-6 hover:text-orange-600 cursor-pointer' />
                        </div>
                      </div>
                    </td> */}