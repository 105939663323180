import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { Button, Input } from "../../../components/ui";
import { useEmployeeContext } from "../../../providers/EditEmployeeProvider";
import Checkbox from "../../../components/ui/Checkbox";
const AddressDetailsForm = ({ onNextStep, onPreviousStep }) => {
  const [
    control,
    handleSubmit,
    setValue,
    getValues,
    educationDoc,
    setEducationDoc,
    workDoc,
    setWorkDoc,
    watch,
  ] = useEmployeeContext();
  const onSubmit = (data) => {
    // console.log(data);
    if (sameAsCurrent) {
      copyAddress(data.addressDetail.currentAddress);
    }
    onNextStep(); // Move to the next step
  };

  const sameAsCurrent = watch("addressDetail.permanentAddress.sameAsCurrent");

  const onChecked = (e) => {
    const values = getValues();
    const currentAddress = values.addressDetail.currentAddress;

    if (e.target.checked) {
      copyAddress(currentAddress);
    }
  };

  const copyAddress = (currentAddress) => {
    setValue(
      "addressDetail.permanentAddress.addressLine1",
      currentAddress.addressLine1
    );
    setValue(
      "addressDetail.permanentAddress.addressLine2",
      currentAddress.addressLine2
    );
    setValue("addressDetail.permanentAddress.pincode", currentAddress.pincode);
    setValue("addressDetail.permanentAddress.state", currentAddress.state);
    setValue("addressDetail.permanentAddress.city", currentAddress.city);
  };

  const onInputChanged = (e, name) => {
    if (sameAsCurrent) {
      setValue(name, e.target.value);
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-6 mt-4">
        <div className="space-y-6">
          <p className="font-semibold">Current Address</p>
          <div className="py-2.5"></div>

          <Input
            control={control}
            label={"Address Line 1 "}
            name={"addressDetail.currentAddress.addressLine1"}
            placeholder={"Please enter address"}
            rules={{
              required: "Address is required",
            }}
            onChangeFn={(e) =>
              onInputChanged(e, "addressDetail.permanentAddress.addressLine1")
            }
          />
          <Input
            control={control}
            label={"Address Line 2 "}
            name={"addressDetail.currentAddress.addressLine2"}
            placeholder={"Please enter address"}
            rules={{
              required: false,
            }}
            onChangeFn={(e) =>
              onInputChanged(e, "addressDetail.permanentAddress.addressLine2")
            }
          />
          <Input
            control={control}
            label={"Pincode "}
            name={"addressDetail.currentAddress.pincode"}
            placeholder={"Please enter pincode"}
            rules={{
              required: "Pincode is required",
              maxLength: {
                value: 6,
                message: "Pincode should have at most 6 digits",
              },
              minLength: {
                value: 6,
                message: "Pincode should have at least 6 digits",
              },

              validate: {
                matchPattern: (v) =>
                  /^(\d{4}|\d{6})$/.test(v) || "Pincode must be valid",
              },
            }}
            onChangeFn={(e) =>
              onInputChanged(e, "addressDetail.permanentAddress.pincode")
            }
          />
          <Input
            control={control}
            label={"State "}
            name={"addressDetail.currentAddress.state"}
            placeholder={"Please enter state"}
            rules={{
              required: "State is required",
            }}
            onChangeFn={(e) =>
              onInputChanged(e, "addressDetail.permanentAddress.state")
            }
          />
          <Input
            control={control}
            label={"City "}
            name={"addressDetail.currentAddress.city"}
            placeholder={"Please enter city"}
            rules={{
              required: "City is required",
            }}
            onChangeFn={(e) =>
              onInputChanged(e, "addressDetail.permanentAddress.city")
            }
          />
        </div>
        <div className="space-y-6">
          <p className="font-semibold mb-5">Permanent Address</p>
          <Checkbox
            control={control}
            name={"addressDetail.permanentAddress.sameAsCurrent"}
            label={"Same as current address"}
            className={" font-semibold "}
            onChangeFn={onChecked}
          />
          {/* <div className="flex justify-end text-sm">
            <input
              id="copy-address"
              type="checkbox"
              className="mr-2"
              onChange={onChecked}
              checked={sameAsCurrent}
            />
            <label for="copy-address"></label>
          </div> */}
          <Input
            control={control}
            label={" Address Line 1 "}
            name={"addressDetail.permanentAddress.addressLine1"}
            placeholder={"Please enter address"}
            rules={{
              required: "Address Line 1 is required",
            }}
            disabled={sameAsCurrent}
          />
          <Input
            control={control}
            label={"Address Line 2 "}
            name={"addressDetail.permanentAddress.addressLine2"}
            placeholder={"Please enter address"}
            rules={{
              required: false,
            }}
            disabled={sameAsCurrent}
          />
          <Input
            control={control}
            label={"Pincode "}
            name={"addressDetail.permanentAddress.pincode"}
            placeholder={"Please enter pincode"}
            rules={{
              required: "Pincode is required",
            }}
            disabled={sameAsCurrent}
          />
          <Input
            control={control}
            label={"State "}
            name={"addressDetail.permanentAddress.state"}
            placeholder={"Please enter state"}
            rules={{
              required: "State is required",
            }}
            disabled={sameAsCurrent}
          />
          <Input
            control={control}
            label={"City "}
            name={"addressDetail.permanentAddress.city"}
            placeholder={"Please enter city"}
            rules={{
              required: "City is required",
            }}
            disabled={sameAsCurrent}
          />
        </div>
      </div>
      <div className="flex justify-center my-5 space-x-3 items-center mt-10">
        <Button variant={"outline"} onClick={onPreviousStep}>
          <ArrowLeftIcon className="mr-2 w-4 h-4" />
          Previous
        </Button>
        <Button className="" onClick={handleSubmit(onSubmit)}>
          Next
          <ArrowRightIcon className="w-4 h-4 ml-2" />
        </Button>
      </div>
    </>
  );
};

export default AddressDetailsForm;
