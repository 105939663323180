import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import usePut from "../../../hooks/usePut";
import spinner from "../../../assets/images/spinner.gif";

const EmployeeSalaryDetails = () => {
  const inputRef = useRef(null);

  useEffect(() => {
    const inputElement = inputRef.current;

    const preventScroll = (e) => {
      e.preventDefault();
    };

    if (inputElement) {
      inputElement.addEventListener('wheel', preventScroll);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('wheel', preventScroll);
      }
    };
  }, []);
  let [searchParams, setSearchParams] = useSearchParams();
  const initialSalaryState = {
    gross_monthly: "",
    gross_yearly: "",
    basic_monthly: "",
    basic_yearly: "",
    da_monthly: "",
    da_yearly: "",
    hra_monthly: "",
    hra_yearly: "",
    conveyance_monthly: "",
    conveyance_yearly: "",
    medical_monthly: "",
    medical_yearly: "",
    other_allowance_monthly: "",
    other_allowance_yearly: "",
    monthly_ctc: "",
    annual_ctc: "",
    is_contract_labour: false,
    tds_percentage: 0,
    auto_calculate: true,
    from_date: new Date(),
  };
  const [salaryForm, setSalaryForm] = useState(initialSalaryState);
  const [isContractualLabour, setIsContractualLabour] = useState(false);
  const [tdsPercentage, setTdsPercentage] = useState(0);

  const { id, username } = useParams();
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const originalDate = new Date(location.state.from_date * 1000);
      setSalaryForm({
        ...location.state,
        from_date: `${originalDate.getFullYear()}-${String(
          originalDate.getMonth() + 1
        ).padStart(2, "0")}-${String(originalDate.getDate()).padStart(2, "0")}`,
      });
    }
  }, [location]);
  const navigate = useNavigate();
  const handleChangeDateDetail = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setSalaryForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const calculateSalary = () => {
    let calculatedSalary = getCalculatedSalary(salaryForm);
    setSalaryForm({ ...calculatedSalary });
  };

  const changeTdsPercentage = (e) => {
    setSalaryForm({
      ...salaryForm,
      tds_percentage: e.target.value,
    });
  };

  function onTdsKeydown(e) {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
    ];

    if (e.target.value.indexOf(".") === -1) {
      allowedKeys.push(".");
    }

    if (allowedKeys.includes(e.key) || (e.key >= "0" && e.key <= "9")) {
      return; // Allow the keypress
    }
    e.preventDefault(); // Prevent the keypress
  }

  const onGrossSalaryChange = () => {
    let value = salaryForm.gross_monthly;

    let salaryObj = {};

    console.log("salaryForm.auto_calculate", salaryForm.auto_calculate);
    if (salaryForm.auto_calculate) {
      salaryObj = {
        ...salaryForm,
        gross_monthly: value,
        basic_monthly: value * 0.51,
        da_monthly: value * 0.25,
        hra_monthly: value * 0.15,
        conveyance_monthly: value * 0.045,
        medical_monthly: value * 0.045,
        // other_allowance_monthly: 0,
      };
    } else {
      salaryObj = {
        ...salaryForm,
        gross_monthly: value,
      };
    }

    let calculatedSalary = getCalculatedSalary(salaryObj);

    setSalaryForm({ ...calculatedSalary });
  };

  const getCalculatedSalary = (salaryObj) => {
    let calculatedSalary = {
      ...salaryObj,
      gross_yearly: salaryObj.gross_monthly * 12,
      basic_yearly: salaryObj.basic_monthly * 12,
      hra_yearly: salaryObj.hra_monthly * 12,
      da_yearly: salaryObj.da_monthly * 12,
      conveyance_yearly: salaryObj.conveyance_monthly * 12,
      other_allowance_yearly: salaryObj.other_allowance_monthly * 12,
      medical_yearly: salaryObj.medical_monthly * 12,
      monthly_ctc:
        salaryObj.basic_monthly +
        salaryObj.hra_monthly +
        salaryObj.conveyance_monthly +
        salaryObj.other_allowance_monthly +
        +salaryObj.da_monthly +
        salaryObj.medical_monthly,
      annual_ctc:
        salaryObj.basic_yearly +
        salaryObj.hra_yearly +
        salaryObj.conveyance_yearly +
        salaryObj.other_allowance_yearly +
        salaryObj.da_yearly +
        salaryObj.medical_yearly,
    };

    return calculatedSalary;
  };

  const handleChangeCents = (e) => {
    let value = +e.target.value;
    let name = e.target.name;
    setSalaryForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onAutoCalculateChange = (e) => {
    setSalaryForm({
      ...salaryForm,
      auto_calculate: e.target.checked,
    });
  };

  function getEsicAmount(monthly_ctc) {
    var esic_percentage = 3.25;
    return Math.ceil(monthly_ctc * (esic_percentage / (esic_percentage + 100)));
  }
  const calculateData = () => {
    calculateSalary();
  };
  const { handleAuth } = usePut({
    url: "createEmployeeSalary",
    onSuccess: () => {
      navigate("/employee-details");
    },
  });
  const updateEmployeeSalary = usePut({
    url: "updateEmployeeSalary",
    onSuccess: () => {
      navigate("/employee-details");
    },
  });
  const handleSaveSalary = () => {
    if (!salaryForm.from_date) {
      return alert('Please Enter All The Details: "EFFECT FROM DATE" ');
    }
    let data = {
      ...salaryForm,
      salary_id: salaryForm.salary_id || null,
      employee_id: id,
      annual_ctc:
        salaryForm.basic_yearly +
        salaryForm.hra_yearly +
        salaryForm.conveyance_yearly +
        salaryForm.other_allowance_yearly +
        salaryForm.da_yearly +
        salaryForm.medical_yearly,
      basic_percentage: salaryForm.basic_ctc_cent,
      hra_percentage: salaryForm.hra_cent,
      conveyance_monthly: salaryForm.conveyance_monthly,
      from_date: Math.floor(new Date(salaryForm.from_date).getTime() / 1000),
    };
    handleAuth.mutate(data);
  };

  const handleUpdateSalary = () => {
    updateEmployeeSalary.handleAuth.mutate(salaryForm);
  };
  return (
    <>
      {handleAuth.isLoading && (
        <div className=" fixed top-0 left-0 z-50 w-full h-full bg-[#ffffffa1] flex items-center justify-center">
          <img src={spinner} className="w-14 h-14 mx-auto" />
        </div>
      )}
      <div className=" px-4 py-4 flex justify-between sticky bg-white top-16 shadow-lg">
        <div className="flex items-center space-x-4">
          <h2 className="text-2xl font-semibold">
            Salary Details for {username}
          </h2>
        </div>
        {salaryForm.salary_id ? (
          <button
            onClick={handleUpdateSalary}
            className="px-5 py-2 bg-yellow-300 rounded font-semibold"
          >
            Update Details
          </button>
        ) : (
          <div className=" space-x-4">
            <button
              onClick={handleSaveSalary}
              className="px-5 py-2 bg-yellow-300 rounded font-semibold"
            >
              Save Details
            </button>
            <Link
              to={`/employee-salary-details/${id}/${username}`}
              className="px-5 py-2 bg-blue-100 rounded font-semibold"
            >
              View Salary
            </Link>
          </div>
        )}
      </div>
      <div className="px-4 my-5">
        <div className="grid grid-cols-3 gap-x-5">
          <div className="">
            <label htmlFor="first_name" className="label">
              Effect From:<span className="mandatory">*</span>
            </label>
            <div>
              <div className="flex items-center space-x-2">
                <input
                  type="date"
                  name="from_date"
                  className="input"
                  value={salaryForm.from_date}
                  placeholder="Enter Annual CTC "
                  onChange={handleChangeDateDetail}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" px-10 border border-gray-200 mt-10 py-">
          <div className="grid grid-cols-8  gap-x-8 border-b border-gray-200 py-4">
            <div className=" col-span-2">
              <label htmlFor="first_name" className="label">
                SALARY COMPONENTS:
              </label>
            </div>
            <div className=" col-span-2">
              <label htmlFor="first_name" className="label">
                MONTHLY AMOUNT:
              </label>
            </div>
            <div className=" col-span-2 text-center">
              <label htmlFor="first_name" className="label">
                ANNUAL AMOUNT:
              </label>
            </div>
          </div>
          <div className="pb-8">
            <div className="mt-5 flex items-center pb-4">
              <h2 className="font-semibold text-xl ">Earnings</h2>

              <div className="ml-auto">
                <div>
                  <input
                    id="auto-calculate"
                    type="checkbox"
                    className="ml-auto mr-2"
                    onChange={onAutoCalculateChange}
                    checked={salaryForm.auto_calculate}
                  />
                  <label htmlFor="auto-calculate">
                    Automatically calculate other components
                  </label>
                </div>
                <div>
                  <input
                    id="is-contractual-labour"
                    type="checkbox"
                    className="mr-2"
                    checked={salaryForm.is_contract_labour}
                    onChange={(e) =>
                      setSalaryForm({
                        ...salaryForm,
                        is_contract_labour: e.target.checked,
                      })
                    }
                  />
                  <label for="is-contractual-labour">
                    Is Contractual Labour?
                  </label>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-8 mt-2 gap-x-8">
              {salaryForm.is_contract_labour && (
                <>
                  <label className="block mb-1 label col-span-2" htmlFor="">
                    TDS percentage:
                  </label>
                  <input
                    className="col-span-2 px-2 py-1 rounded-md border border-input mb-10"
                    type="text"
                    value={salaryForm.tds_percentage}
                    onChange={changeTdsPercentage}
                    onKeyDown={onTdsKeydown}
                  />
                  <div className="col-span-4"></div>
                </>
              )}
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label">
                  GROSS
                </label>
              </div>
              <div className=" col-span-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    name="gross_monthly"
                    value={salaryForm.gross_monthly}
                    onBlur={onGrossSalaryChange}
                    onChange={handleChangeCents}
                    className="input"
                    placeholder=""
                    onWheel={(e) => e.target.blur()}
                    //onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className=" col-span-2 text-center">
                <label
                  htmlFor="first_name"
                  className="label bg-gray-100 input h-[2.6rem]"
                >
                  {salaryForm?.gross_yearly}
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 mt-2 gap-x-8">
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label">
                  BASIC
                </label>
              </div>
              <div className=" col-span-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    name="basic_monthly"
                    value={salaryForm.basic_monthly}
                    onBlur={calculateData}
                    onChange={handleChangeCents}
                    className="input"
                    onWheel={(e) => e.target.blur()}
                    placeholder=""
                    ref={inputRef}
                  />
                </div>
              </div>
              <div className=" col-span-2 text-center">
                <label
                  htmlFor="first_name"
                  className="label bg-gray-100 input h-[2.6rem]"
                >
                  {salaryForm?.basic_yearly}
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 mt-2 gap-x-8">
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label">
                  DA
                </label>
              </div>
              <div className=" col-span-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    name="da_monthly"
                    value={salaryForm.da_monthly}
                    onBlur={calculateData}
                    onChange={handleChangeCents}
                    className="input"
                    placeholder=""
                   onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className=" col-span-2 text-center">
                <label
                  htmlFor="first_name"
                  className="label bg-gray-100 input h-[2.6rem]"
                >
                  {salaryForm?.da_yearly}
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 mt-2 gap-x-8">
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label">
                  HRA
                </label>
              </div>
              <div className=" col-span-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    name="hra_monthly"
                    value={salaryForm.hra_monthly}
                    onBlur={calculateData}
                    onChange={handleChangeCents}
                    className="input"
                    placeholder=""
                   onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className=" col-span-2 text-center">
                <label
                  htmlFor="first_name"
                  className="label bg-gray-100 input h-[2.6rem]"
                >
                  {salaryForm?.hra_yearly}
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 mt-2 gap-x-8">
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label">
                  CONV
                </label>
              </div>
              <div className=" col-span-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    name="conveyance_monthly"
                    value={salaryForm.conveyance_monthly}
                    onBlur={calculateData}
                    onChange={handleChangeCents}
                    className="input"
                    placeholder=""
                   onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className=" col-span-2 text-center">
                <label
                  htmlFor="first_name"
                  className="label bg-gray-100 input h-[2.6rem]"
                >
                  {salaryForm?.conveyance_yearly}
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 mt-2 gap-x-8">
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label">
                  MEDICAL
                </label>
              </div>
              <div className=" col-span-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    name="medical_monthly"
                    value={salaryForm.medical_monthly}
                    onBlur={calculateData}
                    onChange={handleChangeCents}
                    className="input"
                    placeholder=""
                   onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className=" col-span-2 text-center">
                <label
                  htmlFor="first_name"
                  className="label bg-gray-100 input h-[2.6rem]"
                >
                  {salaryForm?.medical_yearly}
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 mt-2 gap-x-8">
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label">
                  OTHERS
                </label>
              </div>
              <div className=" col-span-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    name="other_allowance_monthly"
                    value={salaryForm.other_allowance_monthly}
                    onBlur={calculateData}
                    onChange={handleChangeCents}
                    className="input"
                    placeholder=""
                   onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className=" col-span-2 text-center">
                <label
                  htmlFor="first_name"
                  className="label bg-gray-100 input h-[2.6rem]"
                >
                  {salaryForm?.other_allowance_yearly}
                </label>
              </div>
            </div>
            <div className="grid grid-cols-8 mt-5 gap-x-8 bg-blue-100 rounded -mx-4 px-4 py-2">
              <div className=" col-span-2">
                <label
                  htmlFor="first_name"
                  className="label text-2xl font-semibold"
                >
                  Cost to Company
                </label>
              </div>
              <div className=" col-span-2">
                <label htmlFor="first_name" className="label text-xl">
                  &#8377;{" "}
                  {+salaryForm.basic_monthly +
                    +salaryForm.hra_monthly +
                    +salaryForm.conveyance_monthly +
                    +salaryForm.other_allowance_monthly +
                    +salaryForm.da_monthly +
                    +salaryForm.medical_monthly}
                </label>
              </div>
              <div className=" col-span-2 text-center">
                <label htmlFor="first_name" className="label text-xl">
                  &#8377;{" "}
                  {salaryForm.basic_yearly +
                    salaryForm.hra_yearly +
                    salaryForm.conveyance_yearly +
                    salaryForm.other_allowance_yearly +
                    salaryForm.da_yearly +
                    salaryForm.medical_yearly}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeSalaryDetails;
